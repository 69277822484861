@import "general/colors";
@import "general/newTypography";
@import "general/transitions";
@import "general/sizesSreem";
.new-footer {
    background: $new-blue;
    background-image: url("/src/assets/newFooter/background.png");
    background-position: right top;
    background-repeat: no-repeat;
    padding: 5em 0 4em 0;
    .title {
        color: white;
        max-width: 20em;
        margin-bottom: 1em;
        @include content-title;
    }
    .send-mail {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 3em;
        .send-mail-form {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            &.loading {
                .box-icon {
                    .bx {
                        display: none;
                    }
                    .spinner {
                        display: block;
                    }
                }
            }
            .error-message {
                color: $quaternary;
            }
            .success-message {
                color: white;
            }
        }
        &-box {
            display: flex;
            border: solid 3px $new-yellow;
            border-radius: 0.3em;
            background-color: $new-yellow;
            input {
                background-color: $new-blue;
                padding: 0.8em 1.5em;
                color: white;
                &::placeholder {
                    color: lighten($new-blue, 15%);
                }
            }
            .box-icon {
                background-color: $new-yellow;
                color: white;
                font-size: 1.8em;
                padding: 0 0.5em;
                display: flex;
                justify-content: center;
                align-items: center;
                .bx {
                    display: block;
                }
                .spinner {
                    display: none;
                }
            }
        }
    }
    .contact {
        display: flex;
        gap: 6em;
        color: white;
        margin-bottom: 2em;
        @include content-title-2;
        &-item {
            display: flex;
            align-items: center;
            gap: 0.6em;
            color: white;
            &:hover {
                .text-contact {
                    text-decoration: underline;
                }
            }
            .icon-contact {
                font-size: 2em;
                color: $new-light-blue;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .link-area {
        display: flex;
        gap: 8em;
        margin-bottom: 1.5em;
        &-item {
            display: flex;
            align-items: center;
            gap: 1.5em;
            color: $new-blud-disabled;
            max-width: 10em;
            @include content-title-3;
            .icon-link {
                width: 2.8em;
                height: 2.8em;
                flex: none;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .content {
        display: flex;
        gap: 8em;
        color: $new-blud-disabled;
        @include content-body-1;
        .area {
            max-width: 15em;
            display: flex;
            flex-direction: column;
            .logo-valtec {
                flex-grow: 1;
                display: flex;
                align-items: flex-end;
                a {
                    width: 100%;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            ul {
                list-style: outside;
                margin: 0;
                li {
                    list-style: outside;
                    margin-bottom: 0.5em;
                    a {
                        color: $new-blud-disabled;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
            &.end {
                flex-direction: column;
                max-width: 100%;
                //min-width: 50em;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                color: white;
                flex-grow: 1;
                @include m-responsive {
                    margin-right: 10em;
                }
                .social-icons {
                    display: flex;
                    gap: 0.7em;
                    font-size: 2em;
                    a {
                        color: $new-blud-disabled;
                        transition: color $basic-transition;
                        &:hover {
                            color: white;
                        }
                    }
                }
                .privacy-area {
                    a {
                        color: white;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    .desktop-footer {
        display: initial;
    }
    .movil-footer {
        display: none;
    }
    @media screen and (max-width: 1100px) {
        background-image: none;
        padding: 0;
        padding-top: 2em;
        .desktop-footer {
            display: none;
        }
        .movil-footer {
            display: initial;
        }
        .header-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1.5em;
            flex-wrap: wrap;
            @media screen and (max-width: 620px) {
                flex-direction: column;
                align-items: center;
            }
            .social-icons {
                display: flex;
                gap: 0.5em;
                a {
                    color: white;
                    font-size: 2em;
                    padding: 0 0.2em;
                }
            }
        }
        .contact {
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 0.5em;
            font-size: 0.9em;
            margin-bottom: 1em;
        }
        .sub-title {
            color: $new-blud-disabled;
            font-size: 1.5em;
            font-weight: bold;
            border-bottom: solid 1px $new-blud-disabled;
            padding: 0.5em;
            margin-bottom: 0.5em;
            display: block;
            &:last-child {
                margin-bottom: 2em;
            }
        }
        .area {
            ul {
                list-style: outside;
                li {
                    list-style: outside;
                    color: $new-blud-disabled;
                    margin-bottom: 0.5em;
                }
            }
            a {
                color: $new-blud-disabled;
                text-decoration: underline;
                padding: 0.5em;
            }
        }
        .privacy-area {
            background-color: #023049;
            color: white;
            text-align: center;
            padding: 1em 0;
            font-size: 0.8em;
            a {
                color: white;
            }
        }
    }
}

.spinner {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 3.8px solid #fefefe;
    animation: spinner-bulqg1 0.8s infinite linear alternate, spinner-oaa3wk 1.6s infinite linear;
}

@keyframes spinner-bulqg1 {
    0% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }
    12.5% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }
    25% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }
    62.5% {
        clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }
    75% {
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
}

@keyframes spinner-oaa3wk {
    0% {
        transform: scaleY(1) rotate(0deg);
    }
    49.99% {
        transform: scaleY(1) rotate(135deg);
    }
    50% {
        transform: scaleY(-1) rotate(0deg);
    }
    100% {
        transform: scaleY(-1) rotate(-135deg);
    }
}
